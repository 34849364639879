<template>
  <div class="grindBeans">
    <!-- 返回 -->
    <!-- title="银行卡号认证" -->
    <div class="login-top">
      <div class="login-top-Image"></div>
      <div class="login-top-Text">添加您的银行卡信息，已完成绑卡</div>
    </div>
    <div class="grindBeansFrom">
      <van-form @submit="onSubmit">
        <van-field
          v-model="userInformation.name"
          left-icon="friends-o"
          center
          label="姓名"
          placeholder="请输入姓名"
          clearable
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="userInformation.cardNo"
          label="银行卡号"
          center
          left-icon="orders-o"
          placeholder="请输入银行卡号"
          :rules="[
            { required: true},
          ]"
        />
        <van-field
          v-model="userInformation.phone"
          left-icon="phone-o"
          center
          label="手机号"
          placeholder="预留银行手机号"
          :rules="[{ required: true},
             { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码' }
          ]"
        />
        <van-button style="position: fixed; bottom: 40px;" round block color="#FF6400" native-type="submit">提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import { setUserBlankCarId } from "@/untils/localStorage";
import {
  setWalletBalanceCardId
} from "@/api/myWalletBeans/myWalletBeans";
import { Notify } from "vant";

export default {
  name: "bankCardDeatil",
  components: {
  },
  data() {
    return {
      userInformation: {
        name: "",
        cardNo: "",
        phone: "",
      }
    };
  },
  created() {
    // 用户唯一标识获取
    // 解析唯一标识
      document.title = "绑定银行卡";
  },
  mounted() {
  },
  methods: {
    
    // 提交按钮接口
    onSubmit() {
      setWalletBalanceCardId(this.$route.query.userToken,{...this.userInformation}).then(res=>{
        if(res.data.code == 'OK'){
          // 绑定银行卡
          setUserBlankCarId(res.data.msg)
          Notify({ type: "sucess", message: '绑定银行卡成功' });
          this.$router.push({name:'myWalletBeans'})
        }else{
          Notify({ type: "warning", message: res.data.msg });
        }
      }).catch((error)=>{
        Notify({ type: "warning", message:error});
      })
      // 信息成功跳转到钱包页面
    },
  }
};
</script>





<style scoped lang="scss">
.van-nav-bar__placeholder::v-deep .van-nav-bar {
  background-color: #fff5e2 !important;
}
.grindBeans {
  height: 100vh;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
}
.login-top {
  position: relative;
  height: 238px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFF5E3;
  .login-top-Image{
    width: 296px;
    height: 193px;
    background-image: url('../../../assets/auditingState/bankCard.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login-top-Text {
    position: absolute;
    bottom: 10px;
    width: 100vw;
    font-size: 12px;
    color: #FFA66D;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    background-color: #ffeed0;
  }
}
.grindBeansFrom {
  position: relative;
  padding-top: 10px;
  height: 100%;
  .van-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .van-cell {
      width: 100%;
      height: 50px;
      margin-top: 1px;
      justify-content: center;
    }
  }
  .van-button {
    width: 90%;
    margin-top: 20px;
  }
}
.btDisable {
  cursor: not-allowed;
}
.enable {
  pointer-events: none;
  color: #e2e2e2 !important;
}
</style>
<style  lang="scss">
</style>
